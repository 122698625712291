// Import styles
@import "~@coreui/coreui/scss/coreui.scss";
// Variable overrides

$body-bg:    white;

$footer-bg:    $white;


$sidebar-color:                       $gray-800;
$sidebar-bg:                          $white ;

$sidebar-nav-link-color:              $gray-800;

$sidebar-nav-link-hover-color:        $white;
$sidebar-nav-link-hover-bg:           theme-color("primary") ;

$navbar-hover-color: blue;

$sidebar-nav-link-active-bg:          $white;


$sidebar-minimizer-bg: $white;


$sidebar-minimizer-hover-bg:  theme-color("primary");

$sidebar-nav-dropdown-color:          $gray-800;
$sidebar-nav-dropdown-bg:             $white;
$sidebar-nav-dropdown-indicator-hover-color:$white;
$sidebar-footer-bg:                   $white;

$sidebar-nav-link-active-color: $gray-800;

//$breadcrumb-divider-color: $white;
