// Here you can add other styles

//.main {
//  overflow: hidden;
//}

.reportButtons{
  padding-top: 5px;
  padding-bottom: 5px;
}

.menuSpinner {
  margin: 0 auto;
  margin-top: 10px;
}


.nav-link:hover {
  color: white !important;
}

.nav-link.active {
  color: #20a8d8 !important;
  font-weight:bold ;
}

.nav-link.active:hover {
  color: white !important;
}


.breadcrumb {
  border-bottom: 0;
  margin-bottom:0;
}

.container-fluid {
  height: 150px !important;
}

//IE exception
@media  all and  (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container-fluid {
    height: 90vh !important;
  }
}

@media only screen and (max-width: 700px) {
  .account-email {
    display: none;
  }
  .bookmarkNameSearch {
    width: 100% !important;
  }
}

.mainColor{
  color:#20a8d8;
}

.date-input {
  max-width: 180px;
}

.percentage-input{
  max-width: 90px;
}
